<template>
  <!-- {{ screenSize }}px -->
  <div
    class="mobile__screen__page"
  >
    <div class="content_view"><h4>Download The Go-to Source Mobile App</h4>
      <p>To use The Go-to Source on your mobile device, get the app</p></div>
    <img :src="GooglePlayStoreButton">
    <img :src="AppleStoreButton">
  </div>
</template>

<script>
import AppleStoreButton from './assets/images/icons/apple-button.png'
import GooglePlayStoreButton from './assets/images/icons/google-play-store.png'

export default {
  data() {
    return {
      AppleStoreButton,
      GooglePlayStoreButton,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
