const checkScreenOsMixins = {
  data() {
    return {
      mobile: false,
    }
  },
  methods: {
    checkScreen() {
      // Checking for mobile devices
      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

      // Checking for tablets
      const isTablet = /iPad|Android|Tablet/i.test(navigator.userAgent)

      // check for path
      const path = this.$route.name

      // Checking for desktops
      const isDesktop = !isMobile && !isTablet

      if (isMobile && path === 'login' || isMobile && path === 'register') {
        this.mobile = true
      }

      if (isTablet && path === 'login' || isTablet && path === 'register') {
        this.mobile = true
      }

      if (isDesktop) {
        this.mobile = false
      }
    },
  },
}

export default checkScreenOsMixins
